<template>
  <div>
    <v-row>
      <v-col :cols=6>
        <v-menu
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y 
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="First Session Start Date"
              readonly
              :value="startDate"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="en-in"
            v-model="startDate"
            no-title
            @input="startDateMenu = false"
            :min="minDate"
          ></v-date-picker>
        </v-menu>
      </v-col>
    <v-col :cols=6>
      <v-menu
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y 
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Last Session End Date"
              readonly
              :value="endDate"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="en-in"
            v-model="endDate"
            no-title
            @input="endDateMenu = false"
            :min="minDate"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <p>How long does each session last?</p>
      <v-radio-group v-model="sessionTimeframe">
        <v-row>
          <v-col>
            <v-radio key="day" label="Day" value="day"></v-radio>
          </v-col>
          <v-col>
            <v-radio key="week" label="Week" value="week"></v-radio>
          </v-col>
          <v-col>
            <v-radio key="month" label="Month" value="month"></v-radio>
          </v-col>
        </v-row>
      </v-radio-group>
    </v-row>
    <v-row>
        <v-select
          v-model="startDay"
          :items="firstSessionDays"
          label="What day of the week do sessions start?"
          item-text="name"
          item-value="value"
      ></v-select>
    </v-row>
    <v-row>
      <DaysWidget @updateSessionDays="updateSessionDays" :daysMeet="sessionDays"></DaysWidget>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import DaysWidget from '@/components/DaysWidget.vue'
import store from '@/store.js'

export default {
  name: 'DateWidget',
  components: {
    'DaysWidget': DaysWidget
  },

  data () {
    return {
      minDate: moment().format('YYYY-MM-DD'),
      startDate: this.$store.state.mutliSessionDateObj.startDate,
      endDate: this.$store.state.mutliSessionDateObj.endDate,
      sessionTimeframe: 'week',
      startDay: 1,
      firstSessionDays: [
        {name: 'Sunday', value: 0}, {name:'Monday', value: 1},{ name:'Tuesday', value:2}, {name:'Wednesday', value: 3}, {name: 'Thursday', value: 4}, {name: 'Friday', value: 5}, { name: 'Saturday'}],
      lastDay: {},
      sessionOptions: ['Days', 'Weeks', 'Month'],
      sessionDays: '',
    }
  },
  mounted() {
    this.UpdateSessions()
  },
  methods: {
    CreateSessions() {
      if(this.sessionTimeframe === 'week') {
        return this.CreateWeeklySessions()
      } else if(this.sessionTimeframe === 'month') {
        return this.CreateMonthlySessions()
      } else if(this.sessionTimeframe === 'day') {
        return this.CreateDailySessions()
      } else {
        return this.CreateWeeklySessions()
      }
    },
    CreateDailySessions() {
      let firstDay = moment(this.startDate)
      let lastDay = moment(this.endDate).add(1, 'days')
      let sessions = []
      for(firstDay; firstDay.isBefore(lastDay); firstDay.add(1, 'days')) {
        let currentDay =firstDay.format('dddd').toLowerCase()
        let needToAdd = this.IsCampDay(currentDay)
        if(needToAdd) {
          let session = {
            startDate: firstDay.format('YYYY-MM-DD'),
            endDate: firstDay.format('YYYY-MM-DD'),
            daysMeet: currentDay,
            continuous: false,
            sessionLength: 1,
            sessionUnit: 'Day'
          }
          sessions = [...sessions, session]
        }
      }
      return sessions
    },
    IsCampDay(currentDay) {
      let campDay = false
      let campMeetingDay = ''
      let meetingDays = this.sessionDays.split(',')
      meetingDays.pop()
      for(let i = 0; i<meetingDays.length; i++) {
        switch(meetingDays[i]) {
          case "M":
            if(currentDay === "monday") { campDay = true}
            break;
          case "Tu": 
            if(currentDay === "tuesday") { campDay = true}
            break;
          case "W": 
            if(currentDay === "wednesday") { campDay = true}
            break;
          case "Th": 
            if(currentDay === "thursday") { campDay = true}
            break;
          case "F": 
            if(currentDay === "friday") { campDay = true}
            break;
          case "Sa": 
            if(currentDay === "saturday") { campDay = true}
            break;
          case "Su": 
            if(currentDay === "sunday") { campDay = true}
            break;
        }
      }
      if(campMeetingDay === currentDay) {campDay = true}
      return campDay
    },
    CreateWeeklySessions() {
      let s = this.GetDateOfFirstSessionStart()
      let e = moment(this.endDate)
      let numberOfSessions = e.diff(s, 'week')
      let sessions = []

      for(let i = 0; i<=numberOfSessions; i++){
        let days = this.GetSessionDays(s)

        let session = {
          startDate: days.first,
          endDate: days.last,
          daysMeet: this.sessionDays,
          continuous: false,
          sessionLength: 1,
          sessionUnit: 'Week'
        }
        s.add(1, 'week')
        sessions = [...sessions, session]
      }
      return sessions

    },
    CreateMonthlySessions() {
      let s = moment(this.startDate)
      let e = moment(this.endDate)
      let numberOfSessions = e.diff(s, 'month')
      let sessions = []
      for(let i = 0; i<numberOfSessions; i++){
        let days = this.GetSessionDaysTest(s)

        let session = {
          startDate: days.first,
          endDate: days.last,
          daysMeet: this.sessionDays,
          continuous: false,
          sessionLength: 1,
          sessionUnit: 'Month'
        }
        sessions = [...sessions, session]
      }
      return sessions
    },
    GetDateOfFirstSessionStart() {
      let firstDay
      if(moment(this.startDate).day() > this.startDay.value) {
        firstDay = moment(this.startDate).add(1,'week')
      } else {
        firstDay = moment(this.startDate)
      }
      return firstDay
    },
    GetSessionDaysTest(date){
      let days = {first: date.format('YYYY-MM-DD')}
      days.last = date.add(1, 'month').format('YYYY-MM-DD')
      
      return days
    },
    //for weekly
    GetSessionDays(date){
      let lastDay = this.lastDay?.value ? this.lastDay.value : 5
      let last = moment(date.format('YYYY-MM-DD')).day(lastDay).format('YYYY-MM-DD')
      let days = {first: date.format('YYYY-MM-DD'), last: last}
      return days
    },
    UpdateSessions() {
      let sessions = this.CreateSessions();
      const newObj = {
        startDate: this.startDate,
        endDate: this.endDate,
        sessionDays: this.sessionDays,
        startDay: this.startDay,
        sessionTimeframe: this.sessionTimeframe
      }
      store.commit('updateMutliSessionDateObj', newObj)
      this.$emit('UpdateDateObj', sessions)
    },
    updateSessionDays(days) {
      this.sessionDays = days
    },
  },
  watch: {
    startDate() {
      this.UpdateSessions()
    },
    endDate() {
      this.UpdateSessions()
    },
    sessionDays() {
      this.UpdateSessions()
    },
    startDay() {
      this.UpdateSessions()
    },
    sessionTimeframe() {
      this.UpdateSessions()
    }
  }
}
</script>