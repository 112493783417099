<template>
  <v-container fluid>
    <Navbar></Navbar>
    <div class="form mt-4">
      <h3 class="mb-3">Session Dates</h3>
      <DateWidget
        @UpdateDateObj="((newDateObj) => {
          updateDates(newDateObj)
        })" 
      ></DateWidget>
      
      <CampSessionDetails 
        :session="sessionDetails"
        :formValid="formValid"
        @updateFormValid="((valid) => {this.formValid = valid})"
      ></CampSessionDetails>
    
      <v-row>
        <v-btn
          color="primary"
          class="ml-3 white--text"
          @click="ReviewSession()"
          :disabled="!formValid"
        >
        Review Sessions
      </v-btn>
        <v-btn
          color="primary"
          class="ml-3 white--text"
          @click="ClearForm()"
        >
        Clear Form
      </v-btn>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment'
import DateWidget from '../DateWidget.vue'
import CampSessionDetails from '../CampDashboard/CampSessionDetails.vue'
import Navbar from '../Navbar.vue';

export default {
  name: 'MultiSessionForm',
  components: {
      'DateWidget': DateWidget,
      'CampSessionDetails': CampSessionDetails,
      "Navbar": Navbar
  },
  data () {
    return {
      sessionDetails: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipcode: "",
        price: "",
        bookItUrl: "",
        title: "",
        ageYoungest: "",
        ageOldest: "",
        campStartTime: "",
        campEndTime: "",
        overnight: false,
        busService: false,
        meals: false,
        beforeCare: false,
        beforeCareStart: "",
        afterCare: false,
        afterCareEnd: "",
        categories: [],
        sessionDescription: "",
        virtualCamp: false
      },
      camp: this.$store.state.camp,
      endDate: this.$store.state.multiSessionEndDate ? moment(this.$store.state.multiSessionEndDate).format('YYYY-MM-DD') : moment().add(1, 'month').format('YYYY-MM-DD'),
      dayEvening: [ {name: 'am', value: 'am'},  {name: 'pm', value: 'pm'}],
      campName: this.$store.state.campName,
      formValid: false,
      sessionsByDate: []
    }
  },
  methods: {
    setFormValid(valid) {
      this.formValid = valid
    },
    updateDates(newDates) {
      this.sessionsByDate = newDates.map(date =>{return {...date}})
    },
    ReviewSession() {
      let sessions = []
      this.camp = JSON.parse(localStorage.getItem("myCamp"))[0]

      for(let i = 0; i<this.sessionsByDate.length; i++) {
        let session = {
          indexNum: i,
          continuous: this.sessionsByDate[i].continuous,
          daysMeet: this.sessionsByDate[i].daysMeet,
          endDate: this.sessionsByDate[i].endDate,
          sessionLength: this.sessionsByDate[i].sessionLength,
          sessionUnit: this.sessionsByDate[i].sessionUnit,
          startDate: this.sessionsByDate[i].startDate,
          addressLine1: this.sessionDetails.addressLine1,
          addressLine2: this.sessionDetails.addressLine2,
          city: this.sessionDetails.city,
          state: this.sessionDetails.state,
          zipcode: this.sessionDetails.zipcode,
          price: this.sessionDetails.price,
          bookItUrl: this.sessionDetails.bookItUrl,
          title: this.sessionDetails.title,
          ageYoungest: this.sessionDetails.ageYoungest,
          ageOldest: this.sessionDetails.ageOldest,
          campStartTime: this.sessionDetails.campStartTime,
          campEndTime: this.sessionDetails.campEndTime,
          overnight: this.sessionDetails.overnight,
          busService: this.sessionDetails.busService,
          meals: this.sessionDetails.meals,
          beforeCare: this.sessionDetails.beforeCare,
          beforeCareStart: this.sessionDetails.beforeCareStart,
          afterCare: this.sessionDetails.afterCare,
          afterCareEnd: this.sessionDetails.afterCareEnd,
          categories: this.sessionDetails.categories,
          sessionDescription: this.sessionDetails.sessionDescription,
          virtualCamp: this.sessionDetails.virtualCamp,
          campName: this.camp.campName,
          campId: this.camp.campId
        }
        sessions.push(session)
      }
      this.$store.commit('updateSessionsToCreate', sessions)
      localStorage.setItem('sessionsToCreate', JSON.stringify(sessions))
      this.$router.push('/camp/sessions/review')
    },
  },
}
</script>

<style scoped>
.form {
  margin: 0 10%;
}

tr .text-start .colorPrimary{
  color: #666666 !important
}

.v-icon.v-icon {
  display: block;
  color: #fff !important;
}

h1 {
  color: #ffffff;
}

</style>
