import { render, staticRenderFns } from "./CampSessionDetails.vue?vue&type=template&id=45628e57&scoped=true"
import script from "./CampSessionDetails.vue?vue&type=script&lang=js"
export * from "./CampSessionDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45628e57",
  null
  
)

export default component.exports