<template>
<div>    
<v-row>
  <p>What day(s) do you meet?</p>
  <v-radio-group v-model="sessionDays">
    <v-row>
      <v-col :cols=6>
      <v-btn  tn  @click="AddSessionDays([ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'])">Monday - Friday</v-btn>
      </v-col>
      <v-col :cols=6>
        <v-btn  @click="AddSessionDays(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'])">Sunday - Friday</v-btn>
      </v-col>
    </v-row>
  </v-radio-group>
</v-row>
<v-row>
  <v-col>
    <v-checkbox v-model="sunday" label="Sunday"></v-checkbox>
  </v-col>
  <v-col>
    <v-checkbox v-model="monday" label="Monday"></v-checkbox>
  </v-col>
  <v-col>
    <v-checkbox v-model="tuesday" label="Tuesday"></v-checkbox>
  </v-col>
  <v-col>
    <v-checkbox v-model="wednesday" label="Wednesday"></v-checkbox>
  </v-col>
  <v-col>
    <v-checkbox v-model="thursday" label="Thursday"></v-checkbox>
  </v-col>
  <v-col>
    <v-checkbox v-model="friday" label="Friday"></v-checkbox>
  </v-col>
  <v-col>
    <v-checkbox v-model="saturday" label="Saturday"></v-checkbox>
  </v-col>
  <v-col></v-col>
</v-row>
</div>
</template>

<script>
export default {
  name: 'DaysWidget',
  props: ['daysMeet'],
  data () {
    return {
      sessionDays: '',
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    }
  },
  mounted() {
    this.DisplayDaysMeet()
  },
  methods: {
    UpdateDays() {
      let days = ''
      if(this.monday) {days += 'M,'}
      if(this.tuesday) {days += 'Tu,'}
      if(this.wednesday){ days += 'W,'}
      if(this.thursday) {days += 'Th,'}
      if(this.friday) {days += 'F,'}
      if(this.saturday) {days += 'Sa,'}
      if(this.sunday) {days += 'Su,'}
      this.$emit('updateSessionDays', days)
    },
    DisplayDaysMeet(){
      if(this.daysMeet && this.daysMeet !== '') {
        
        let daysArray = this.daysMeet.split(",")
        for(var i = 0; i<daysArray.length; i++){
          switch(daysArray[i]) {
            case "M": 
              this.monday = true
              break
            case 'Tu':
              this.tuesday = true
                break
              case 'W':
                this.wednesday = true
                break
              case 'Th':
                this.thursday = true
                break
              case 'F':
                this.friday = true
                break
              case 'Sa':
                this.saturday = true
                break
              case 'Su':
                this.sunday = true
                break
              default:
                break
            }
          }
        }
    },
    AddSessionDays(days) {
      this.ClearDays()
      days.forEach(day => {
        this.AddDay(day)
      });
    },
    ClearDays() {
      this.monday = false
      this.tuesday = false
      this.wednesday = false
      this.thursday = false
      this.friday = false
      this.saturday = false
      this.sunday = false
    },
    AddDay(day) {
      switch(day) {
        case 'Monday':
          this.monday = true
          return
        case 'Tuesday':
          this.tuesday = true
          return
        case 'Wednesday':
          this.wednesday = true
          return
        case 'Thursday':
          this.thursday = true
          return
        case 'Friday':
          this.friday = true
          return
        case 'Saturday':
          this.saturday = true
          return
        case 'Sunday':
          this.sunday = true
          return
        default:
          return
      }
    },
  },
  watch: {
    monday() {
      this.UpdateDays();
    },
    tuesday() {
      this.UpdateDays();
    },
    wednesday() {
      this.UpdateDays();
    },
    thursday() {
      this.UpdateDays();
    },
    friday() {
      this.UpdateDays();
    },
    saturday() {
      this.UpdateDays();
    },
    sunday() {
      this.UpdateDays();
    },
    daysMeet(days) {
      if(days === "") {this.ClearDays()} else { this.DisplayDaysMeet()}
    }
  }
}
</script>