<template>
  <div>
    <v-form ref="sessionForm" @submit.prevent="">
      
    <v-divider class="mt-3 mb-3"></v-divider>
    <h3>Session Location</h3>
    <v-row>
      <v-col>
        <v-checkbox v-model="session.virtualCamp" label="No address (Virtual Camp)"
          :rules="[rules.virtualCampRules]"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-show="!session.virtualCamp">
      <v-col :cols=6>
        <v-text-field 
          v-model="session.addressLine1"
          label="Session Address Line 1"
          type="text"
          :rules="[rules.addressLine1Rules]"
        ></v-text-field>
      </v-col>
      <v-col :cols=6>
        <v-text-field 
          v-model="session.addressLine2" 
          type="text" 
          label="Session Address Line 2"
        ></v-text-field>
      </v-col>
      <v-col :cols=6>
        <v-text-field 
          v-model="session.city" 
          type="text" 
          label="City"
          :rules="[rules.city]"
        ></v-text-field>
      </v-col>
      <v-col :cols=3>
        <!-- <v-autocomplete
          v-model="session.state"
          :items="states"
          color="white"
          hide-no-data
          hide-selected
          item-text="Description"
          placeholder="State"
          :rules="[rules.state]"
        ></v-autocomplete> -->
        <v-autocomplete
  v-model="session.state"
  :items="states"
  color="white"
  hide-no-data
  hide-selected
  item-text="text"
  item-value="value"
  placeholder="State"
  :rules="[rules.state]"
></v-autocomplete>
      </v-col>
      <v-col :cols=3>
        <v-text-field 
          v-model="session.zipcode"
          type="text" 
          label="Zipcode"
          :rules="[rules.zipcode]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mt-3 mb-3"></v-divider>
    <h3>Session Details</h3>
    <v-row>
      <v-col :cols=6>
        <v-text-field 
          label="Price"
          v-model="session.price"
          type="number"
          :rules="[rules.price]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
          label="BookIt Url"
          v-model="session.bookItUrl"
          :rules="[rules.bookItUrl]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
          label="Title"
          v-model="session.title"
          :rules="[rules.title]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols=6>
          <v-text-field
          label="Youngest Age Accepted"
          v-model="session.ageYoungest"
          :rules="[rules.ageYoungest]"
          type="number"
          ></v-text-field>
        </v-col>
        <v-col :cols=6>
          <v-text-field
          label="Oldest Age Accepted"
          v-model="session.ageOldest"
          :rules="[rules.ageOldest]"
          type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols=6>
          <v-text-field
          label="Camp Start Time"
          v-model="session.campStartTime"
          :rules="[rules.campStartTime]"
          type="time"
          ></v-text-field>
        </v-col>
        <v-col :cols=6>
          <v-text-field
          label="Camp End Time"
          v-model="session.campEndTime"
          :rules="[rules.campEndTime]"
          type="time"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols=4>
        <v-switch v-model="session.overnight"  class="ma-2" label="Overnight"></v-switch>
      </v-col>
      <v-col :cols=4>
        <v-switch v-model="session.busService"  class="ma-2" label="Bus Service"></v-switch>
      </v-col>
      <v-col :cols=4>
        <v-switch v-model="session.meals"  class="ma-2" label="Meals"></v-switch>
      </v-col>
    </v-row>
      <v-row>
      <v-col :cols=4>
        <v-switch v-model="session.beforeCare" class="ma-2" label="Before Care"></v-switch>
        <v-text-field v-if="session.beforeCare" label="Before Care Start Time" v-model="session.beforeCareStart" type="time"></v-text-field>
      </v-col>
      <v-col :cols=4>
        <v-switch v-model="session.afterCare" class="ma-2" label="After Care"></v-switch>
        <v-text-field v-if="session.afterCare" label="After Care End Time" v-model="session.afterCareEnd" type="time"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
       <v-col>
         <v-combobox
          v-model="session.categories"
          :items="categories"
          chips
          clearable
          label="Session Categories"
          multiple
          solo
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="remove(item)"
            >
              <strong>{{ item }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-combobox>
       </v-col>
    </v-row>
    <v-row>
      <v-col>
      <v-textarea
          v-model="session.sessionDescription"
          label="Description"
          row-height="24"
          rows="4"
        ></v-textarea>
      </v-col>
    </v-row>
    </v-form>
  </div>
</template>

<script>
import CategoriesService from '@/api-services/categories.service.js';

export default {
  name: 'CampSessionDetails',
  props: ['session', 'formValid'],
  data () {
    return {
      categories: this.$store.state.categories,
      states: [
      { text: "Alabama", value: "AL" },
      { text: "Alaska", value: "AK" },
      { text: "Arizona", value: "AZ" },
      { text: "Arkansas", value: "AR" },
      { text: "California", value: "CA" },
      { text: "Colorado", value: "CO" },
      { text: "Connecticut", value: "CT" },
      { text: "Delaware", value: "DE" },
      { text: "Florida", value: "FL" },
      { text: "Georgia", value: "GA" },
      { text: "Hawaii", value: "HI" },
      { text: "Idaho", value: "ID" },
      { text: "Illinois", value: "IL" },
      { text: "Indiana", value: "IN" },
      { text: "Iowa", value: "IA" },
      { text: "Kansas", value: "KS" },
      { text: "Kentucky", value: "KY" },
      { text: "Louisiana", value: "LA" },
      { text: "Maine", value: "ME" },
      { text: "Maryland", value: "MD" },
      { text: "Massachusetts", value: "MA" },
      { text: "Michigan", value: "MI" },
      { text: "Minnesota", value: "MN" },
      { text: "Mississippi", value: "MS" },
      { text: "Missouri", value: "MO" },
      { text: "Montana", value: "MT" },
      { text: "Nebraska", value: "NE" },
      { text: "Nevada", value: "NV" },
      { text: "New Hampshire", value: "NH" },
      { text: "New Jersey", value: "NJ" },
      { text: "New Mexico", value: "NM" },
      { text: "New York", value: "NY" },
      { text: "North Carolina", value: "NC" },
      { text: "North Dakota", value: "ND" },
      { text: "Ohio", value: "OH" },
      { text: "Oklahoma", value: "OK" },
      { text: "Oregon", value: "OR" },
      { text: "Pennsylvania", value: "PA" },
      { text: "Rhode Island", value: "RI" },
      { text: "South Carolina", value: "SC" },
      { text: "South Dakota", value: "SD" },
      { text: "Tennessee", value: "TN" },
      { text: "Texas", value: "TX" },
      { text: "Utah", value: "UT" },
      { text: "Vermont", value: "VT" },
      { text: "Virginia", value: "VA" },
      { text: "Washington", value: "WA" },
      { text: "West Virginia", value: "WV" },
      { text: "Wisconsin", value: "WI" },
      { text: "Wyoming", value: "WY" }

    ],
      virtualCamp: false,
      rules: {
        virtualCampRules: value => {
          this.FormValid()
          return !!value || ''
        },
        addressLine1Rules: value => {
          this.FormValid()
          return !!value || 'Address is required'
        },
        city: value => {
          this.FormValid()
          return !!value || 'City is required'
        },
        state: value => {
          this.FormValid()
          return !!value || 'State is required'
        },
        zipcode: value => {
          this.FormValid()
          return !!value || 'Zipcode is required'
        },
        price: value => {
          this.FormValid()
          return !!value || "Price Required"
        },
        bookItUrl: value => {
          this.FormValid()
          return !!value || "BookIt Url Required"
        },
        title: value => {
          this.FormValid()
          return !!value || "Title Required"
        },
        ageOldest: value => {
          this.FormValid()
          return !!value || "Age Oldest Required"
        },
        ageYoungest: value => {
          this.FormValid()
          return !!value || "Age Youngest Required"
        },
        campStartTime: value => {
          this.FormValid()
          return !!value || "Camp Start Time Required"
        },
        campEndTime: value => {
          this.FormValid()
          return !!value || "Camp End Time Required"
        }
      }
    }
  },
  async mounted() {
    
    if(this.$store.state.categories.length < 1) {
      CategoriesService.getCategories()
      .then((response) => {
        let categories = []
        response.data.forEach(cat => {
          categories.push(cat.categoryText)
        });
        this.categories = categories
        this.$store.commit('updateCategories', categories)
      })
    }
  },
  methods: {
    remove(item) {
      let catToRemove = this.session.categories.findIndex(el => el === item)
      if(catToRemove === -1) {
        catToRemove = this.session.categories.findIndex(el => el === item)
      }
      this.session.categories.splice(catToRemove,1)
    },
    FormValid() {
      let valid = this.AddressValid()
      this.session.price ? '' : valid = false
      const urlValid = this.UrlValid(this.session.bookItUrl)
      urlValid ? '' : valid = false
      this.session.title ? '' : valid = false
      this.session.ageOldest ? '' : valid = false
      this.session.ageYoungest ? '' : valid = false
      this.session.campStartTime ? '' : valid = false
      this.session.campEndTime ? '' : valid = false
      this.$emit('updateFormValid', valid)
    },
    UrlValid(url) {
      let valid = false
      const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
      url.match(regexp)? valid = true : valid = false
      return valid
    },
    AddressValid() {
      if(!this.session.virtualCamp) {
        if(this.session.addressLine1 === "" || this.session.city === "" || this.session.state === "" || this.session.zipcode === "" ){
          
          // this.$emit('updateFormValid', false)
          return false
        } else {
          // this.$emit('updateFormValid', true)
          return true
        }
      } else {
          // this.$emit('updateFormValid', true)
          return true
      }
    }
  },
  watch: {
    virtualCamp(val) {
      if(val) {
        this.address = {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipcode: "",
        }
        // this.$emit('updateFormValid', true)
      } else {
        this.FormValid()
      }
    }
  }
}
</script>

<style scoped>


</style>
